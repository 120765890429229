import axios from '@/axios.js'
import store from '@/store/index.js';
import { setAnimatorOnlineStatus } from "./store/index.js";

class Auth {
    constructor() {

//         this.token = window.localStorage.getItem('token');
//         let userData = window.localStorage.getItem('website_user');

        this.token = store.state.token;
        let userData = store.state.user;
//         console.log('userData',JSON.parse(JSON.stringify(userData)))
          this.user = userData ? JSON.parse(JSON.stringify(userData)) : null;
//
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
//
        this.fetchUser()
        this.is_client = false
        if (store.state.user && (store.state.user.website_user)) {
            this.is_client = true;
        }
        console.log('is_client', this.is_client, store.state.user);

        // if (!this.is_client) {
            this.inactivityTime = 30 * 60 * 1000; // 30minutes en millisecondes
            this.timeout = null;
            this.initInactivityTimer();
        // }
        
    }

    async fetchUser(){

        try{
            //window.localStorage.setItem('website_user', JSON.stringify(resp.data.user));
            let resp = await axios.get('user')
            store.commit('SET_USER', resp.data)
        } catch (e) {
            console.log('errorfetchUser', e)
        }
    }

    login(token, user) {
        console.log('login user', user)
        this.token = token;
        this.user = user;

        store.commit('SET_TOKEN', this.token)
        store.commit('SET_USER', user)

        if (this.user.website_user) {
            this.updateUserSession()
        }

        //window.localStorage.setItem('token', token);
        //window.localStorage.setItem('website_user', JSON.stringify(user));

        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        // if (!this.is_client) {
            this.resetInactivityTimer();
        // }



    }
    async autologin(endpoint) {
        try{
            let resp = await axios.get(endpoint)
            console.log('resp.data autologin', resp.data);
            let token = resp.data.access_token
            let user = resp.data.user
            await this.login(token,user)
        } catch (e) {
            // alert('error')
            console.log('e', e)
        }
    }

    async updateUserSession() {
        try {
          let response = await axios.get('credits_and_count_transactions_successed/'+this.user.website_user.id)
          console.log('updateUserSession');
          let nb_credits = response.data.data.credits;
          let nb_transactions_successed = response.data.data.count_transactions_successed;
          if (nb_credits <= 0) {
            return;
          }
          store.commit('SET_COUNT_MESSAGES_SENTS_IF_NO_CREDITS', true)
          store.commit('SET_USER_CREDITS', nb_credits)
          if (nb_transactions_successed > 0) {
            store.commit('SET_NB_TRANSACTIONS_SUCCESSED', nb_transactions_successed)
          }        
          console.log('updateUserCredits');
        } catch (e) {
          console.error('error', e);
        }
    }

    check() {
        return !!this.token;
    }
    async logout() { 
        if (store.state.user && store.state.user.animator) {
            try {
                if (store.state.user.animator.online) {
                    // Mettre à jour l'attribut online côté serveur
                    try {
                        await axios.put('/animator/setOffline', {
                            animator_id: store.state.user.animator.id
                        });
                        console.log('Animator online status updated in the database');

                        console.log("start logout");
                        let resp = await axios.post("auth/logout/"+store.state.user.id);
                        console.log("resp logout", resp, resp.data);
                    } catch (error) {
                        console.error('Error updating animator online status:', error);
                    }
                }
            } catch (error) {
                console.error('Error on logout anim', error);
            }            
        }
    
        // Déconnexion de l'utilisateur
        await store.commit("SET_USER", null);
        await store.commit("SET_COUNT_MESSAGES_SENTS_IF_NO_CREDITS", true);
        await store.commit("SET_NB_TRANSACTIONS_SUCCESSED", 0);
        // await store.commit("SET_CURRENT_CONVERSATION", true);
        await store.commit("SET_TOKEN", null);
        await store.commit("SET_MESSAGES_PENDING_COUNT", 0);
        this.user = null;
        this.token = null;
    
        console.log("User and token set to null:", store.state.user, store.state.token);
        window.location.href = '/'
    }

    initInactivityTimer() {
        console.log('init inactivity');
        this.resetInactivityTimer();
        window.addEventListener('mousemove', this.resetInactivityTimer.bind(this));
        window.addEventListener('keydown', this.resetInactivityTimer.bind(this));
    }

    resetInactivityTimer() {
        console.log('reset inactivity');
        clearTimeout(this.timeout);
        this.timeout = setTimeout(this.logout.bind(this), this.inactivityTime);
    }
}
export default new Auth();