/**
 ** auth
 */

//import layoutAuth from '@/langs/fr/layout/auth.js'
import pageAuthLogin from '@/langs/fr/pages/auth/login.js'
import pageAuthRecoverPassword from '@/langs/fr/pages/auth/recover-password.js'
import pageAuthSetNewPassword from '@/langs/fr/pages/auth/set-new-password.js'

export default {
  pageAuthLogin,
  pageAuthRecoverPassword,
  pageAuthSetNewPassword,
}